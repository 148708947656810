/* Mobile S */
@media only screen and (max-width: 320px) {
    html{
        font-size: 80%;
    }
    body{overflow-x: hidden;}
    .offers-grid,.projects{
        grid-template-columns: 1fr;
    }
    .offers-grid .offer:nth-child(2){
        transform: scale(1);
    }
    .project-detail{
        position: unset;
        transform: unset;
    }
    section#home::before {
        content: none;
    }
    section#home{
        background-image: url(../../../public/images/your_success.webp);
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: #ffffffb8;
        background-blend-mode: color;
    }
    .skills .skill{
        flex-direction: column;
        text-align: center;
    }
    .skill .skill-title{
        flex-direction: column;
        border-right: none;
        border-bottom: 1px solid lightgrey;
        margin-bottom: 16px;
    }
    section#experiences::before{
        content: none;
    }
    section#experiences{
        background-image: url('../../../public/images/figure-image1.webp');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: #ffffffd4;
        background-blend-mode: color;
    }
    .offers-grid .offer:nth-child(2){
        box-shadow: none;
    }
    .nav{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        text-align: center;
        display:  none;
    }
    .nav li{
        display: block;
        padding: 16px;
        font-size: larger;
    }
    .s-box {
        position: absolute;
        right: 0%;
    }
    .show-m-nav{
        display: block;
    }
}
/* Mobile M */
/* Mobile S */
@media only screen and (min-width:321px) and (max-width: 426px) {
    html{
        font-size: 80%;
    }
    body{overflow-x: hidden;}
    .offers-grid,.projects{
        grid-template-columns: 1fr;
    }
    .offers-grid .offer:nth-child(2){
        transform: scale(1);
    }
    .project-detail{
        position: unset;
        transform: unset;
    }
    section#home::before {
        content: none;
    }
    section#home{
        background-image: url(../../../public/images/your_success.webp);
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: #ffffffb8;
        background-blend-mode: color;
    }
    .skills .skill{
        flex-direction: column;
        text-align: center;
    }
    .skill .skill-title{
        flex-direction: column;
        border-right: none;
        border-bottom: 1px solid lightgrey;
        margin-bottom: 16px;
    }
    section#experiences::before{
        content: none;
    }
    section#experiences{
        background-image: url('../../../public/images/figure-image1.webp');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: #ffffffd4;
        background-blend-mode: color;
    }
    .offers-grid .offer:nth-child(2){
        box-shadow: none;
    }
    .nav{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        text-align: center;
        display:  none;
    }
    .nav li{
        display: block;
        padding: 16px;
        font-size: larger;
    }
    .s-box {
        position: absolute;
        right: 0%;
    }
    .show-m-nav{
        display: block;
    }
}
@media only screen and (max-width: 768px) {
    nav ul{
        padding: 0px;
        width: 90%;
    }
    ul.localisation {
        display: flex;
    }
    .nav li.lang-btn {
        padding: 4px;
    }
    header nav > ul:nth-child(1) > li:nth-last-child(2){
        float: left;
    }
    header nav > ul:nth-child(1) > li:nth-last-child(1){
        float: right;
    }
    header nav {
        flex-direction: column;
    }
}
@media only screen and (min-width:426px) and (max-width: 768px) {
    html{
        font-size: 80%;
    }
    body{overflow-x: hidden;}
    .projects{
        grid-template-columns: 1fr 1fr;
    }
    .offers-grid{
        grid-template-columns: 1fr 1fr 1fr;
    }
    .offers-grid .offer:nth-child(2){
        transform: scale(1);
    }
    .project-detail{
        position: unset;
        transform: unset;
    }
    section#home::before {
        content: none;
    }
    section#home{
        background-image: url(../../../public/images/your_success.webp);
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: #ffffffb8;
        background-blend-mode: color;
    }
    .skills .skill{
        flex-direction: column;
        text-align: center;
    }
    .skill .skill-title{
        flex-direction: column;
        border-right: none;
        border-bottom: 1px solid lightgrey;
        margin-bottom: 16px;
    }
    section#experiences::before{
        content: none;
    }
    section#experiences{
        background-image: url('../../../public/images/figure-image1.webp');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: #ffffffd4;
        background-blend-mode: color;
    }
    .offers-grid .offer:nth-child(2){
        box-shadow: none;
    }
    .nav{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        text-align: center;
        display:  none;
    }
    .nav li{
        display: block;
        padding: 16px;
        font-size: larger;
    }
    .s-box {
        position: absolute;
        right: 0%;
    }
    .show-m-nav{
        display: block;
    }
}
