/* TypeScale */
@import url("https://fonts.googleapis.com/css?family=Rubik:400&display=swap");
@import url('../../../node_modules/bootstrap/dist/css/bootstrap.css');
@import url('../../../node_modules/@fortawesome/fontawesome-free/css/all.css');
html {
  font-size: 90%;
}

body {
  background: white;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 1.75;
  color: #000000;
  overflow-x: hidden;
}

p {
  margin-bottom: 1rem;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 3rem 0 1.38rem;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 1.3;
}

h1 {
  margin-top: 0;
  font-size: 4.209rem;
}

h2 {
  font-size: 3.157rem;
}

h3 {
  font-size: 2.369rem;
}

h4 {
  font-size: 1.777rem;
}

h5 {
  font-size: 1.333rem;
}

small,
.text_small,::placeholder,label {
  font-size: 0.85rem;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.btn:hover{
  filter: hue-rotate(100deg);
}
.btn{
  border: none;
}
/* Buttons  */
.btn-primary {
  background-image: linear-gradient(96deg, #9289f1 0%, #6254e7 100%);
  border:none;
}
.btn-warning {
  background-image: linear-gradient(45deg, #ef3b58, #fe7725);
  border-color: #fe7725;
  border:none;
  color: white;
}
.btn-danger {
  background-image: linear-gradient(96deg, #f44336 0%, #e91e63 100%);
  border-color: #f44336;
  border:none;
  color: white;
}
text-danger{
    color: #ef3b58;
}
/* Layouts */
header {
  /* background-color: rgb(255, 255, 255); */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  padding: 16px;
  transition: 0.2s;
}
header nav{
    display: flex;
    align-items: space-between;
    justify-content: center;
    width: 100%;
    flex-direction: row;
}
header .s-col ul{
    padding: 0;
}
li.header-s {
    position: relative;
}
.bg-w-70 {
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: rgb(0 0 0 / 16%) 0px 0px 16px;
}
.logo-holder img {
  margin-right: 10%;
  max-width: 150px;
}
header ul {
  margin: 0;
}
header div:nth-child(3) ul {
  margin: 0;
  display: flex;
  justify-content: flex-end;
  align-content: center;
}
header li {
  display: inline-block;
  padding: 8px;
}
header a.active {
    color: #ef3d5a;
    font-weight: bold;
}
header a {
    color: black;
    text-decoration: none;
}
main > section {
  padding-top: 64px;
  padding-bottom: 64px;
}
section#home h1 {
  font-weight: bold;
}
/* header div.row > div:nth-child(1){} */
header div.row > div:nth-child(2) {
  /* justify-content: left;
    align-items: center; */
}
/* header div.row > div:nth-child(3){} */
section#home {
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
}
section#home::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 70%;
  height: 100%;
  background-image: url("../../../public/images/your_success.webp");
  background-position: right top;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
}
section#home div.col > span {
  font-size: 32px;
}
.service-card {
  padding: 16px;
  border-radius: 16px;
  height: 100%;
}
.skills .skill {
  border-radius: 16px;
  margin-bottom: 32px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: start;
}
.skill .skill-title > span {
  font-size: xx-large;
  display: block;
  text-align: center;
  font-weight: bold;
}
.skill .skill-title {
  display: block;
  min-width: 150px;
  text-align: center;
  margin-right: 15px;
  border-right: 1px solid lightgrey;
}
section#presentation {
  position: relative;
}
section#presentation::before {
  content: "";
  position: absolute;
  left: 0%;
  top: 0%;
  width: 250px;
  height: 250px;
  background-image: url("../../../public/images/element_01.webp");
  background-position: center left;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
}
section#presentation::after {
  content: "";
  position: absolute;
  right: 0%;
  top: 30%;
  width: 250px;
  height: 250px;
  background-image: url("../../../public/images/element_03.webp");
  background-position: center right;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -2;
}
/* Experiences */
section#experiences {
  position: relative;
}
section#experiences::before {
  content: "";
  position: absolute;
  left: 0%;
  top: 0%;
  width: 100%;
  min-height: 100%;
  background-image: url("../../../public/images/figure-image1.webp");
  background-position: left top;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
}
.row > div {
  height: 100%;
}
.progress {
  height: 0.5rem;
}
.projects {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
}
.project {
  /* border-radius: 16px; */
  overflow: hidden;
  position: relative;
}
.project-detail {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.95);
  transform: translateY(100%);
  transition: 0.5s;
}
.project::before{
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: 0.5s;
  opacity: 0;
}
.project:hover img {
  /* background: #ffffff6b; */
  opacity: .5;
}
.project:hover .project-detail p.small {
    color: initial;
}
.project:hover .project-detail {
  transform: translateY(0%);
}
.project:hover .project-detail {
  transform: translateY(0%);
}
.offers-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
  margin-top: 96px;
}
.offers-grid .offer:nth-child(2) {
  transform: scale(1.1);
  position: relative;
  z-index: 1;
  box-shadow: #630000ad 1px 16px 40px;
  border: none !important;
}
.offer {
  border-radius: 16px;
  text-align: center;
  padding: 16px;
}
.offer ul {
  list-style: none;
}
.offer ul li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding: 16px;
}
.offer ul li:last-child {
  border: none;
}

.offer-price {
  font-size: 5rem;
  position: relative;
}
.offer-price::before {
  content: "from ";
  color: white;
  position: absolute;
  margin: auto;
  font-size: 1rem;
  opacity: 0.5;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
::placeholder {
  opacity: 0.6 !important;
  font-size: 0.9rem;
}
#audit {
    background-image: url("../../../public/images/home5_bg_01.webp");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: soft-light;
    background-color: #1d272e;
}
#audit input {
    border-width: 3px;
    box-sizing: content-box;
}
#call-to-action {
  background-image: url("../../../public/images/call_01.webp");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
#f-about ul {
  list-style: none;
}
#f-about ul li {
  display: inline-block;
  margin: 16px;
}
#header-s {
  position: relative;
  z-index: 9;
}
.s-box {
  position: absolute;
  right: 100%;
  display: none;
  background-color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: #0000002e 0px 7px 15px;
}
.s-box form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}
.s-inp {
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  padding: 5px 15px;
}
.s-box button {
  border-radius: 5px;
  border: none;
  background-image: linear-gradient(45deg, #ef3b58, #fe7725);
  padding: 0px 10px;
  margin-left: 5px;
  color: #ffffff;
}
.s-box.show {
  display: block;
}
.nav {
  transition: 0.5s;
}
.nav.show {
  display: flex;
  z-index: 9;
}
/* .show-m-nav{
    position: relative;
    z-index: 0;
} */

/*  header menu bars icon */
.show-m-nav {
  display: none;
  width: 20px;
  height: 15px;
  position: relative;
  margin: 5px auto;
  /* -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); */
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 9;
}
.show-m-nav span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: black;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  /* -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg); */
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
/* Icon 3 */

.show-m-nav span:nth-child(1) {
  top: 0px;
}

.show-m-nav span:nth-child(2),
.show-m-nav span:nth-child(3) {
  top: 6px;
}

.show-m-nav span:nth-child(4) {
  top: 12px;
}

.show-m-nav.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.show-m-nav.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.show-m-nav.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.show-m-nav.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}
.header-spacer {
  height: 85px;
  width: 100%;
}
.page-view {
  background: #ffffff;
  aspect-ratio: 16 / 9;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  margin-bottom: 16px;
}
.page-view .page-holder {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden auto;
}
.page-view .page-holder::-webkit-scrollbar {width: 5px;}
.page-view .page-holder::-webkit-scrollbar-thumb {background-color: #1d2124;}
.page-view .page-holder img {
  width: 100%;
  min-height: 100%;
}
.site-details {
  margin-bottom: 15px;
  padding: 15px;
}
footer{
    background: #1d272e;
    color: #ffffff;
    display: flex;
    flex-direction: column;
}

.text-md-end{
    text-align: end !important;
}
.text-md-start{
    text-align: start !important;
}

.lang-btn a{
    text-decoration: none;
}
.lang-btn {
  background: #c8cacc;
  margin-inline: 5px;
  width: fit-content;
  border-radius: 6px;
  font-size: .5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border: none;
  text-decoration: none;
  cursor: pointer;
  margin-block: 8px;
  height: fit-content;
  padding: 6px;
  font-size: .8rem;
}
.lang-btn.active{
    background: #ef3d5a;
    color: #ffffff;
}
.domain-alert {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.domain-alert i {
    font-size: 2rem;
}
.domain-alert span{
    margin-inline-start: 8px;
}
.frozen {
    background-image: linear-gradient(89deg, #efefef,#ffffff,#efefef, #efefef);
    background-size: calc(160px + 160px);
    animation: frozen 1s infinite linear;
}

.pagination{
    width: fit-content;
    margin: auto;
}

.pagination .page-link{
    height: 35px;
    width: 35px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    margin: 5px;
    cursor: pointer;
}
.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #ef3d5a;
    border-color: #ef3d5a;
    box-shadow: #ef3d5a 0px 0px 6px;
}
.page-item.disabled .page-link {
    cursor: unset;
    opacity: 0.3;
}
.page-item:last-child .page-link,.page-item:first-child .page-link  {
    border-radius: 30px;
    background: #877cef;
    color: #ffffff;
}
#services{
  margin-top: -24vh;
  position: relative;
  z-index: 99;
}
#services .service-card {
  background: #ffffffed;
}